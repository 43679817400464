import React from "react"
import { graphql } from 'gatsby'
import { Layout } from "@components/Layout";
import { SEO, mapToSEO } from "@components/SEO";
import { Banner, mapToPageHeader } from "@components/Banner";
import { mapToKiwiVIPVisible, SignupBannerWrapper } from "@components/SignupBanner";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { Grid, Responsive } from "semantic-ui-react";
import { PlateFormatList, VehicleTypeList, AvailableSizeList, mapToPlateDesignFormatDataFromPage, mapToRedesignInfo } from "@components/VehicleInfoList";
import { KenticoChoice } from "@utils/KontentTypes";
import { mapToPlateSizes, getVehicleTypeByQueryString, getTransactionTypeByQueryString, getCombinationByQueryString, getIsGiftingByQueryString } from "@utils/Helpers";
import PlateDesignPreview from "@components/PlateDesignsPreview/PlateDesignsPreview";
import { mapToProductModelCategoryList } from "@mappers/index";
import { calculatePlateMetaInfo } from "@mappers/ProductModels/mapper";
import { VehicleType, TransactionType } from "@models/ProductModels";
import Cookies from "js-cookie";

const queryString = require('query-string');
type Props = any
interface StateData {
  vehicleType: VehicleType
  transactionType: TransactionType
  formattedCombination: string
  isGifting: boolean
}


class PlateDesign extends React.Component<Props>{

  loadQueryString = (search: any): StateData => {
    let vehicleType: VehicleType = getVehicleTypeByQueryString(search.vt);
    let transactionType: TransactionType = getTransactionTypeByQueryString(search.tt);
    let formattedCombination = getCombinationByQueryString(search.fcomb, vehicleType);
    let isGifting = getIsGiftingByQueryString(search.gift);
    return {
      vehicleType: vehicleType,
      transactionType: transactionType,
      formattedCombination: formattedCombination,
      isGifting: isGifting
    }
  }

  render() {


    const { data, location } = this.props;
    const item = data.kontentItemProductCategory.elements;
    const apiUrl = data.site.siteMetadata.apiUrl;
    const parsed = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
    const query = this.loadQueryString(parsed);

    const productFormatsMetadata = data.kontentItemAbLandingPage.elements.create_plate.linked_items[0].elements.plate_formats.linked_items;
    
    const plateDesignInfo = calculatePlateMetaInfo(item, query.formattedCombination);
    const vehicleTpes: KenticoChoice[] = item.vehicle_types.value; //array of codename
    const plateSizes: KenticoChoice[] = item.plate_sizes.value //array of name and codename
    const formatData = mapToPlateDesignFormatDataFromPage(item, productFormatsMetadata);    
    const redesignUpdateData = mapToRedesignInfo(item);
    let availableVehicleTypes: string[] = [];
    vehicleTpes.forEach(vt => availableVehicleTypes.push(vt.codename));
    var availablePlateSizes = mapToPlateSizes(plateSizes);
    const categories = mapToProductModelCategoryList(plateDesignInfo.name, item.product_designs.linked_items, query.vehicleType);
    const defaultDesign = categories.buildDefaultPlateDesignProps();
    const kiwiVip = mapToKiwiVIPVisible(item);
    const seo = mapToSEO(item);
    const metadata = mapToPageHeader(item);

    if (this.props.location.state?.selectedPlateDesign) {
      defaultDesign.backPlate = this.props.location.state.selectedPlateDesign.plate
      defaultDesign.frontPlate = this.props.location.state.selectedPlateDesign.plate
      defaultDesign.plateColor = this.props.location.state.selectedPlateDesign.plateColor
    }

    return (
      <Layout location={location}>
        <SEO {...seo} />
        <Banner {...metadata} isBiggerBanner={false} />
        <PlateDesignPreview
          apiUrl={apiUrl}
          productCategoryList={categories}
          transactionType={query.transactionType}
          metaInfo={plateDesignInfo}
          formattedCombination={query.formattedCombination}
          currentPlateDesign={defaultDesign}
          showBackToAllDesign={true}
          vehicleType={query.vehicleType} />
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 20,
              bottom: 20
            },
            desktop: {
              top: 40,
              bottom: 40
            }
          }}
          backgroundColor="#EBEBEB">
          <Grid>
            <Grid.Row>
              <Responsive as={Grid.Column} computer={4} minWidth={Responsive.onlyComputer.minWidth}>
                <PlateFormatList
                  title="Create a New Plate"
                  data={formatData} />
              </Responsive>
              <Responsive as={Grid.Column} computer={4} minWidth={Responsive.onlyComputer.minWidth}>
                <PlateFormatList
                  title="Keep your Combination"
                  data={redesignUpdateData} />
              </Responsive>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <VehicleTypeList
                  title="Vehicle Types"
                  availableTypes={availableVehicleTypes}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <AvailableSizeList
                  title="Size Available"
                  availableSizes={availablePlateSizes} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </GenericBannerContainer>
        {
          kiwiVip.visible &&
          <SignupBannerWrapper version={kiwiVip.version} />
        }
      </Layout>
    )
  }
}

export const query = graphql`
query($slug: String!){
  site {
    siteMetadata {
      apiUrl
    }
  }
    allKontentItemProductCategoryFormats{
      edges{
        node{
          elements{
            title{
              value
            }
            description{
              value
            }
          }
        }
      }
    }
    kontentItemAbLandingPage {
      elements {
        create_plate {
          linked_items {
            ... on
            KontentItemCreateNewPlate {
              elements {
                plate_formats {
                  linked_items {
                    ... on KontentItemProductCategoryFormats {
                      id
                      elements {
                        description {
                          value
                        }
                        format_type {
                          value {
                            codename
                            name
                          }
                        }
                        base_price {
                          value
                        }
                        example_image {
                          value {
                            url
                            description
                          }
                        }
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    kontentItemProductCategory(fields: { slug: { eq: $slug }}) {
      elements {
        category_name {
          value
        }
        new_design {
          value {
            codename
            name
          }
        }          
        lock_caption_colors {
          value {
            codename
            name
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        category_description_rich {
          value
        }
        vehicle_types {
          value {
            name
            codename
          }
        }
        plate_sizes {
          value {
            name
            codename
          }
        }
        upgrade_title {
          value
        }
        upgrade_description {
          value
        }
        upgrade_starting_price {
          value
        }
        redesign_title {
          value
        }
        redesign_description {
          value
        }
        redesign_starting_price {
          value
        }
        fixed_fomat_price {
          value
        }
        flexi_format_price {
          value
        }
        premium_3_character_price {
          value
        }
        premium_2_character_price {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__noindex {
          value {
            name
            codename
          }
        }
        url{
          value
        }
        product_designs {
          linked_items {
            ... on KontentItemPlateDesign {
              elements {
                name {
                  value
                }
                dealer_only_design {
                  value {
                    name
                    codename
                  }
                }
                disable_dual_sizing {
                  value {
                    codename
                    name
                  }
                }
                authorized_dealers {
                  linked_items {
                    ... on KontentItemAuthorizedDealers {
                      elements {
                        title {
                          value
                        }
                        link_message {
                          value
                        }
                        description {
                          value
                        }
                        dealer_contacts {
                          linked_items {
                            ... on KontentItemDealerContact {
                              elements {
                                dealer_name {
                                  value
                                }
                                contact_name {
                                  value
                                }
                                phone {
                                  value
                                }
                                formatted_phone_number {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                transaction_types {
                  value {
                    name
                    codename
                  }
                }
                vehicle_types {
                  value {
                    name
                    codename
                  }
                }
                kpi_plate_design_code {
                  value {
                    name
                    codename
                  }
                }
                kpi_plate_foreground_color {
                  value {
                    name
                    codename
                  }
                }
                plate_size {
                  value {
                    name
                    codename
                  }
                }
                plate_color {
                  value {
                    name
                    codename
                  }
                }
                base_price {
                  value
                }
                redesign_price {
                  value
                }
                govt_upgrade_price {
                  value
                }
                captions {
                  value {
                    name
                    codename
                  }
                }
                max_message_length__mm_ {
                  value
                }
                bottom_caption_max_length__mm_ {
                  value
                }
                message_colors {
                  value {
                    name
                    codename
                  }
                }
                european_brand_name {
                  value {
                    name
                    codename
                  }
                }
                dual_size_plate {
                  linked_items {
                    ... on KontentItemPlateDesign {
                      elements {
                        name {
                          value
                        }
                        dealer_only_design {
                          value {
                            name
                            codename
                          }
                        }
                        disable_dual_sizing {
                          value {
                            codename
                            name
                          }
                        }
                        authorized_dealers {
                          linked_items {
                            ... on KontentItemAuthorizedDealers {
                              elements {
                                title {
                                  value
                                }
                                link_message {
                                  value
                                }
                                description {
                                  value
                                }
                                dealer_contacts {
                                  linked_items {
                                    ... on KontentItemDealerContact {
                                      elements {
                                        dealer_name {
                                          value
                                        }
                                        contact_name {
                                          value
                                        }
                                        phone {
                                          value
                                        }
                                        formatted_phone_number {
                                          value
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        transaction_types {
                          value {
                            name
                            codename
                          }
                        }
                        vehicle_types {
                          value {
                            name
                            codename
                          }
                        }
                        kpi_plate_design_code {
                          value {
                            name
                            codename
                          }
                        }
                        kpi_plate_foreground_color {
                          value {
                            name
                            codename
                          }
                        }
                        plate_size {
                          value {
                            name
                            codename
                          }
                        }
                        plate_color {
                          value {
                            name
                            codename
                          }
                        }
                        base_price {
                          value
                        }
                        redesign_price {
                          value
                        }
                        govt_upgrade_price {
                          value
                        }
                        captions {
                          value {
                            name
                            codename
                          }
                        }
                        max_message_length__mm_ {
                          value
                        }
                        bottom_caption_max_length__mm_ {
                          value
                        }
                        message_colors {
                          value {
                            name
                            codename
                          }
                        }
                        european_brand_name {
                          value {
                            name
                            codename
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
}
`

export default PlateDesign